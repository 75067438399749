<template src="@/../../../templates/brand/components/templates/shop/Collection.html"> </template>

<script>
// imports
import _ from 'lodash/fp'
import { mapActions, mapState } from 'vuex'
import mainNav from '@/components/MainNav.vue'
import productTile from '@/components/shop/product/ProductTile.vue'
import Kramed from 'kramed'
Kramed.setOptions({
  renderer: new Kramed.Renderer(),
  gfm: true,
  langPrefix: '',
  tables: true,
  breaks: false,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: true
})

export default {
  name: 'Collection',
  mounted () {
    window.onscroll = () => {
      if (
        window.innerHeight + window.pageYOffset - this.$el.offsetTop >= this.$el.clientHeight
      ) {
        // setTimeout(() => {
        if (!this.updatingView) {
          this.updatingView = true
          this.showing += 6
        }
        // }, 1000);
      }
    }
  },
  watch: {
    $route: function (val) {
      if (val.params.cat1) {
        this.filterCategories = _.isArray(val.params.cat1) ? val.params.cat1 : val.params.cat1.split('/')
      } else {
        this.filterCategories = []
      }
    },
    filteredProducts: function (val) {
      if (val.length === this.showing && this.updatingView)
        this.updatingView = false
      let _ctx = ~this.$route.path.indexOf('product')
        ? this.$route.path.slice(0, this.$route.path.indexOf('product'))
        : this.$route.path
      this.setContext(_ctx, val)
    }
  },
  components: { mainNav, productTile /*, countDown */ },
  data () {
    return {
      // collection: {body: ''},
      excludeCats: [],
      filterCategories: [],
      loaded: false,
      list: 0,
      renderchunk: 10,
      showing: 6,
      updatingView: false,
      kramed: Kramed
    }
  },
  computed: {
    ...mapState({
      collections: ({ Collection }) => Collection.collections,
      collection: ({ Collection }) =>
        Collection.collection || {
          name: '',
          content: { en: {}, fr: {} },
          url: ''
        },
      products: ({ Product }) => Product.listFamilies, // Product.familyVariations,
      collectionLocale: ({ Collection }) =>
        Collection.collectionLocale || 'default',
      user: ({ App }) => App.user
    }),
    filteredProducts: function () {
      if (!this.filterCategories.length) {
        return this.products.slice(0, this.showing)
      }
      return _.filter(p => {
        let _cats = _.map(c => (c && c.url) || null, p.category)
        return _.every(c => ~_cats.indexOf(c), this.filterCategories)
      }, this.products).slice(0, this.showing)
    },
    departments: function () {
      const _cats = _.flatten(_.map(p => p.category, this.products))
      const _urls = _.uniq(_.map(c => c && c.url, _cats))
      return _.compact(_.map(c => _.find(i => i && i.url === c, _cats), _urls))
    }
  },
  // component methods
  methods: {
    ...mapActions([
      'getCollections',
      'getCollectionProducts',
      'setCollection',
      'setContext'
    ]),
    filterDepts: () => true
  },
  async created () {
    if (!this.collections || !this.collections.length) {
      await this.getCollections().then(async () => {
        await this.setCollection(this.$route.params.collection)
      })
    } else {
      await this.setCollection(this.$route.params.collection)
    }
    if (
      !this.products.length ||
      !_.every(p => ~_.map(c => c.url, p.section).indexOf(this.$route.params.collection), this.products)
    ) {
      this.getCollectionProducts({
        id: this.collection._id, //this.$route.params.collection,
        key: 'url'
      }).then(() => {
        this.loaded = true
      })
    } else {
      this.loaded = true
    }
    if (this.$route.params.cat1) {
      this.filterCategories = _.isArray(this.$route.params.cat1) ? this.$route.params.cat1 : this.$route.params.cat1.split('/')
    } else {
      this.filterCategories = []
    }
    this.setContext(this.$route.path)
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/Collection.css"></style>
